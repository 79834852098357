<template>
  <div>
    <b-form-group :label="$t('user.new-password.password-label')">
      <b-form-input
        v-model="password"
        :state="passwordIsValid"
        type="password"
        required
        :placeholder="$t('user.new-password.password-placeholder')"
      ></b-form-input>
    </b-form-group>
    <b-form-group :label="$t('user.new-password.confirmation-label')">
      <b-form-input
        v-model="passwordConfirmation"
        type="password"
        :state="passwordConfirmationIsValid"
        required
        :placeholder="$t('user.new-password.confirmation-placeholder')"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      passwordConfirmation: ""
    };
  },
  computed: {
    passwordIsValid() {
      return this.password !== "";
    },
    passwordConfirmationIsValid() {
      return this.passwordConfirmation !== "" && this.password === this.passwordConfirmation;
    },
    isValid() {
      return this.passwordIsValid && this.passwordConfirmationIsValid;
    }
  },
  methods: {
    clear() {
      this.password = "";
      this.passwordConfirmation = "";
    }
  }
};
</script>
