<template>
  <b-container>
    <user-settings></user-settings>
  </b-container>
</template>

<script>
import UserSettings from "@/components/user/UserSettings.vue";

export default {
  components: {
    userSettings: UserSettings
  }
};
</script>
