<template>
  <div>
    <b-form-group :label="$t('user.user-settings.change-password.old-password-label')">
      <b-form-input
        v-model="oldPassword"
        :state="passwordIsValid"
        type="password"
        :placeholder="$t('user.user-settings.change-password.old-password-placeholder')"
      ></b-form-input>
    </b-form-group>
    <new-password ref="newPasswordComponent"></new-password>
  </div>
</template>

<script>
import NewPassword from "@/components/user/NewPassword.vue";

export default {
  components: {
    newPassword: NewPassword
  },
  data() {
    return {
      oldPassword: ""
    };
  },
  computed: {
    newPassword() {
      return this.$refs.newPasswordComponent.password;
    },
    passwordIsValid() {
      return this.oldPassword !== "";
    },
    isValid() {
      return this.passwordIsValid && this.$refs.newPasswordComponent.isValid;
    }
  },
  methods: {
    clear() {
      this.oldPassword = "";
      this.$refs.newPasswordComponent.clear();
    }
  },
  mounted() {
    this.clear();
  }
};
</script>
