<template>
  <div v-if="!userInfoIsLoading">
    <b-row class="mb-2">
      <h3>{{ localizedUserRole + " " + userInfo.login }}</h3>
    </b-row>
    <b-row>
      <b-button @click="onShowChangePasswordModal" class="mr-2 mb-2">{{
        $t("user.user-settings.change-password-btn-caption")
      }}</b-button>
      <b-button @click="onLogoffClick" class="mb-2">{{ $t("user.user-settings.log-off-btn-caption") }}</b-button>
    </b-row>
    <b-modal
      id="change-password-modal"
      size="lg"
      :ok-title="$t('user.user-settings.change-password-modal.ok-title')"
      :cancel-title="$t('user.user-settings.change-password-modal.cancel-title')"
      :title="$t('user.user-settings.change-password-modal.title')"
      ref="changePasswordModal"
      @ok="onChangePasswordClick"
    >
      <template v-slot:default>
        <change-password ref="changePasswordComponent"></change-password>
      </template>
    </b-modal>
  </div>
  <div v-else>
    {{ $t("user.user-settings.loading") }}
  </div>
</template>

<script>
import auth from "@/services/auth.js";
import { LOGOUT } from "@/const/events.js";
import ChangePassword from "@/components/user/ChangePassword.vue";
import { ADMINISTRATOR, MANAGER, DRIVER } from "@/const/user-role.js";
import redirectWithChecksMixin from "@/mixins/redirect-with-check.js";

export default {
  mixins: [redirectWithChecksMixin],
  components: {
    changePassword: ChangePassword
  },
  computed: {
    userInfo() {
      return this.$store.state.userSettings.userInfo;
    },
    userInfoIsLoading() {
      return this.$store.state.userSettings.userInfoIsLoading;
    },
    localizedUserRole() {
      switch (this.userInfo.role) {
        case ADMINISTRATOR:
          return this.$t("roles.administrator");
        case MANAGER:
          return this.$t("roles.manager");
        case DRIVER:
          return this.$t("roles.driver");
        default:
          return this.$t("roles.no-role");
      }
    }
  },
  methods: {
    onLogoffClick() {
      auth.clear();
      this.$bus.emit(LOGOUT);
      this.redirectToRoute("home");
    },
    onShowChangePasswordModal() {
      this.$bvModal.show("change-password-modal");
    },
    onChangePasswordClick(e) {
      e.preventDefault();

      if (!this.$refs.changePasswordComponent.isValid) {
        alert(this.$t("user.user-settings.change-password-validation-error"));
        return;
      }

      var vm = {
        oldPassword: this.$refs.changePasswordComponent.oldPassword,
        newPassword: this.$refs.changePasswordComponent.newPassword
      };

      this.$store
        .dispatch("userSettings/changePassword", vm)
        .then(() => {
          alert(this.$t("user.user-settings.password-changed"));
          this.$bvModal.hide("change-password-modal");
        })
        .catch(resp => {
          if (resp.data.errorCode) {
            alert(this.$t("user.user-settings.password-change-error." + resp.data.errorCode));
          } else {
            alert(this.$t("user.user-settings.password-change-error.general-error"));
          }
        });
    }
  },
  mounted() {
    this.$store.dispatch("userSettings/getUserInfo");
  }
};
</script>
